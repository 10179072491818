<template>
    <div class="business">
        <!-- 营业概况 -->
        <div class="total">
            <!-- <div class="totalItem">
                <div class="totalItem_t">原单金额</div>
                <div class="totalItem_b">{{head.totalPrice}}</div>
            </div> -->
            <div class="totalItem flex_3">
                <div class="totalItem_t">实收金额({{head.payedPrice}})</div>
                <div class="totalItem_b_3">
                    <div>微信：{{head.wechatPay}}</div>
                    <div>支付宝：{{head.aliPay}}</div>
                    <!-- <div>其他：{{head.otherPay}}</div> -->
                    <div>云闪付：{{head.bankPay}}</div>
                    <div>现金：{{head.cashPay}}</div>
                    <div>余额支付：{{head.balancePay}}</div>
                </div>
            </div>
            <div class="totalItem flex_2">
                <div class="totalItem_t">优惠金额</div>
                <div class="totalItem_b">{{head.discountPrice}}</div>
            </div>
            <div class="totalItem flex_2">
                <div class="totalItem_t">原单金额</div>
                <div class="totalItem_b">{{head.totalPrice}}</div>
            </div>
            <!-- <div class="totalItem flex_2" v-if="FOOD_TYPE == 'feast'">
                <div class="totalItem_t">赠送金额</div>
                <div class="totalItem_b">{{head.givingPrice}}</div>
            </div> -->
            <!-- <div class="totalItem">
                <div class="totalItem_t"> 退款金额(笔数)</div>
                <div class="totalItem_b">{{head.refundNum}}</div>
            </div> -->
            <!-- <div class="totalItem" v-if="FOOD_TYPE == 'feast'">
                <div class="totalItem_t"> 销账金额(笔数)</div>
                <div class="totalItem_b">{{head.hangNum}}</div>
            </div> -->
        </div>
        <div class="detail">
            <div class="detail_item">
                <div class="item_title">订单收款</div>
                <div class="detail_t">
                    <div style="flex:6;">订单号</div>
                    <div style="flex:2;">支付</div>
                    <div style="flex:2;text-align: right;">金额</div>
                </div>
                <div class="detail_border"></div>
                <div class="shoukuan">
                    <div class="detail_b" v-for="(item,i) in paylog" :key="i">
                        <div style="flex:6;">{{item.order_no}}</div>
                        <div style="flex:2;">{{item.type_desc}}</div>
                        <div style="flex:2;text-align: right;">{{item.payed_price}}</div>
                    </div>
                </div>
                
            </div>
            <div class="detail_item">
                <div class="item_title">充值</div>
                <div class="chongzhi">
                    <div>充值：{{rechargeHead.rechargeTotal}}</div>
                    <div>赠送：{{rechargeHead.giftTotal}}</div>
                </div>
                <div class="detail_t">
                    <div style="flex:4;">支付</div>
                    <div style="flex:2;">笔数</div>
                    <div style="flex:3;text-align: right;">金额</div>
                </div>
                <div class="detail_border"></div>
                <div class="chongzhiList">
                    <div class="detail_b" v-for="(item,i) in rechargeList" :key="i">
                        <div style="flex:4;">{{item.type_desc}}</div>
                        <div style="flex:2;">{{item.count}}</div>
                        <div style="flex:3;text-align: right;">{{item.price}}</div>
                    </div>
                </div>
            </div>
            <div class="detail_item" style="margin-right:0;">
                <div class="item_title">会员消费</div>
                <div class="chongzhi">
                    <div>总额：{{balanceLog.balancePrice}}</div>
                    <div>次数：{{balanceLog.balanceNum}}</div>
                </div>
                <div class="detail_t">
                    <div style="flex:4;">会员</div>
                    <div style="flex:5;">订单号</div>
                    <div style="flex:3;text-align: right;">金额</div>
                </div>
                <div class="detail_border"></div>
                <div class="chongzhiList">
                    <div class="detail_b" v-for="(item,i) in balanceList" :key="i">
                        <div style="flex:4;">{{item.nick_name}}</div>
                        <div style="flex:5;">{{item.order_no}}</div>
                        <div style="flex:3;text-align: right;">￥{{item.payed_price}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div>
                <!-- <div class="btn" @click="PrintHandle">打印交班单</div> -->
            </div>
            <div class="bottom_r">
                <a-checkbox v-model="printReceipt">
                    <span class="textColor">打印交班单</span>
                </a-checkbox>     
                <a-button class="smallOkBtn" @click="showOutModal">交班并退出</a-button>
            </div>
        </div>
    </div>
</template>
<script>
import { businessSituation , schedule } from '@/request/shift'
import Cookies from "js-cookie";
import { mapState , mapActions } from "vuex";
export default {
    name:'business',
    props:['refresh','timeData','schedule_type'],
    data(){
        return{
            head:{},
            paylog:[],
            rechargeHead:{},
            rechargeList:[],
            balanceLog:{},
            printReceipt:true,
            printType:2 ,  //1 打印并交班退出   2 仅打印
            logout_close:'',
            balanceList:[],
            FOOD_TYPE:''
        }
    },
    computed:{
        ...mapState(["websockKey","websockData"]),
    },
    watch:{
        refresh(newVal){
            this.getBusinessData()
        },
        websockKey(){
            if(this.websockKey === "jiaoban"){
                if(this.websockData.value.status == 200){
                    this.$message.success(this.websockData.value.message)
                    if(this.printType == 1){
                        schedule().then(res => {
                            if(res.data.status == 200){
                                this.$message.success('交班成功')
                                let username = localStorage.getItem('username');
                                let Ar=JSON.parse(localStorage.getItem('Ar'));
                                let user=JSON.parse(localStorage.getItem('user'));
                                let FOOD_TYPE = localStorage.getItem('FOOD_TYPE')
                                let OEM = localStorage.getItem('OEM')
                                let install=JSON.parse(localStorage.getItem('install'))
                                let screen=JSON.parse(localStorage.getItem('screen'))
                                let LSSP_printer_id=JSON.parse(localStorage.getItem('LSSP_printer_id'))
                                let printReceipt = localStorage.getItem('printReceipt')
                                localStorage.clear()
                                localStorage.setItem('printReceipt', printReceipt)
                                localStorage.setItem('LSSP_printer_id', LSSP_printer_id)
                                localStorage.setItem('OEM', OEM)
                                localStorage.setItem('FOOD_TYPE', FOOD_TYPE)
                                localStorage.setItem('username',username)
                                localStorage.setItem('Ar',JSON.stringify(Ar))
                                localStorage.setItem('user',JSON.stringify(user))
                                localStorage.setItem('install', JSON.stringify(install))
                                localStorage.setItem('screen', JSON.stringify(screen))
                                Cookies.remove('Access-Token')
                                this.indexDB.deleteAWeekAgo()
                                bound.deleteCache()
                                this.updateTabListAction([{
                                    title:'主页面',
                                    path:'/about',
                                    name: 'About',
                                    isDetele:false
                                }])
                                this.activeNameAction('About')
                                if(this.logout_close === 'close'){
                                    setTimeout(() => {
                                        bound.formClose();
                                    },800)
                                }else{
                                    this.$router.push("/");
                                }
                                
                            }
                        })
                    }
                }else{
                    this.$message.error(this.websockData.value.message);
                }
            }
        }
    },
    mounted(){
        this.getBusinessData()
        this.logout_close = this.$route.query.jiaoban
        this.FOOD_TYPE = localStorage.getItem('FOOD_TYPE') || 'feast'
        this.printReceipt = localStorage.getItem('printReceipt') == 1 ? true : false
    },
    methods:{
        ...mapActions(['updateTabListAction','activeNameAction']),
        getBusinessData(){
            let data = {}
            if(this.timeData && this.timeData.length == 2){
                data = {
                    start_time: this.timeData[0],
                    end_time: this.timeData[1],
                    schedule_type: this.schedule_type
                }
            }else{
                data = {
                    schedule_type: this.schedule_type
                }
            }
            businessSituation(data).then(res => {
                if(res.data.status == 200){
                    this.head = res.data.data.head
                    this.paylog = res.data.data.paylog
                    this.rechargeHead = res.data.data.rechargeHead
                    this.rechargeList = res.data.data.rechargeList
                    this.balanceLog = res.data.data.balanceLog
                    this.balanceList = res.data.data.balanceList
                }
            })
            
        },
        showOutModal(){
            let _this = this
            this.$confirm({
                title: "提示",
                content: "确定要交班并退出吗？",
                okText: "确定",
                cancelText: "取消",
                onOk() {
                    _this.scheduleOut()
                },
                onCancel() {},
            });
        },
        scheduleOut(){  //交班并退出
            localStorage.setItem('printReceipt',this.printReceipt ? 1 : 2)
            if(this.printReceipt){
                //打交班单并交班退出
                this.printType = 1
                this.socketApi.websocketsend({key:"jiaobanAction", value:{schedule_type :1}});
            }else{
                // 交班退出不打单
                console.log('this.logout_close',this.logout_close);
                schedule().then(res => {
                    if(res.data.status == 200){
                        this.$message.success('交班成功')
                        
                        let Ar=JSON.parse(localStorage.getItem('Ar'));
                        let username = localStorage.getItem('username')
                        let user=JSON.parse(localStorage.getItem('user'));
                        let FOOD_TYPE = localStorage.getItem('FOOD_TYPE')
                        let OEM = localStorage.getItem('OEM')
                        let install=JSON.parse(localStorage.getItem('install'))
                        let screen=JSON.parse(localStorage.getItem('screen'))
                        let LSSP_printer_id=JSON.parse(localStorage.getItem('LSSP_printer_id'))
                        let printReceipt = localStorage.getItem('printReceipt')
                        localStorage.clear()
                        localStorage.setItem('printReceipt', printReceipt)
                        localStorage.setItem('LSSP_printer_id', LSSP_printer_id)
                        localStorage.setItem('OEM', OEM)
                        localStorage.setItem('FOOD_TYPE', FOOD_TYPE)
                        localStorage.setItem('username',username)
                        // localStorage.setItem('jiaoban',true)
                        localStorage.setItem('Ar',JSON.stringify(Ar))
                        localStorage.setItem('user',JSON.stringify(user))
                        localStorage.setItem('install', JSON.stringify(install))
                        localStorage.setItem('screen', JSON.stringify(screen))
                        Cookies.remove('Access-Token')
                        this.indexDB.deleteAWeekAgo()
                        bound.deleteCache()
                        this.updateTabListAction([{
                            title:'主页面',
                            path:'/about',
                            name: 'About',
                            isDetele:false
                        }])
                        this.activeNameAction('About')
                        if(this.logout_close === 'close'){
                            setTimeout(() => {
                                // this.$router.push("/");
                                bound.formClose();
                            },1000)
                        }else{
                            this.$router.push("/");
                        }
                        
                        
                    }
                })
            }
        },
        PrintHandle(){
            this.printType = 2
            this.socketApi.websocketsend({key:"jiaobanAction"});
        }
    }
}
</script>
<style lang="less" scoped>
.business{
    .total{
        color: #505050;
        border: 1px solid #d0d0d0;
        border-right:none;
        display: flex;
        
        .totalItem{
            border-right: 1px solid #d0d0d0;
            flex: 6;
            text-align: center;
            line-height: 6vh;
            font-size: 1.8vh;
            .totalItem_t{
                font-size: 1.8vh;
                border-bottom: 1px solid #d0d0d0;
                font-weight: 700;
            }
            .totalItem_b_3{
                display: flex;
                font-size: 1.6vh;
                >div{
                    flex: 1;
                    border-right: 1px solid #d0d0d0;
                    &:last-child{
                        border: none;
                    }
                }
            }
            
        }
        .flex_2{
            flex: 4;
        }
        .flex_3{
            flex: 18;
        }
        
    }
    .detail{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        min-height: 36vh;
        .detail_item{
            
            flex: 1;
            margin-right: 20px;
            border: 1px solid #d0d0d0;
            background: #ffffff;
            border-radius: 4px;
            justify-content: space-between;
            .item_title{
                font-size: 18px;
                color: #505050;
                font-weight: 700;
                text-align: center;
                margin: 2vh 1vw;
            }
            .detail_t{
                display: flex;
                margin-top: 20px;
                // font-weight: 700;
                font-size: 2vh;
                margin: 2vh 1vw;
            }
            .shoukuan{
                height: 27vh;
                overflow: auto;
            }
            .chongzhiList{
                height: 19vh;
                overflow: auto;
            }
            .detail_b{
                display: flex;
                margin-top: 20px;
                font-size: 1.8vh;
                margin: 2vh 1vw;
                
            }
            .shoukuan,.chongzhiList{
                &::-webkit-scrollbar {
                    /*滚动条整体样式*/
                    width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
                    height: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    /*滚动条里面小方块*/
                    border-radius: 3px;
                    background   : #AAAAAA;
                }
                &::-webkit-scrollbar-track {
                    /*滚动条里面轨道*/
                    border-radius: 3px;
                    background   : #CCCACA;
                }
            }
            .detail_border{
                margin-top: 20px;
                border-top: 1px solid #d0d0d0;
                margin: 0 1vw;
            }
            .chongzhi{
                display: flex;
                margin-top: 1vh;
                font-size: 1.8vh;
                background: #f3f3f3;
                padding: 1vh;
                >div{
                    flex: 1;
                    line-height: 4vh;
                    text-align: center;
                    &:first-child{
                        border-right: 1px solid #d0d0d0;
                    }
                }
            }

        }
    }
    .bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .bottom_r{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

</style>