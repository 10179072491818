<template>
    <div class="sales">
        <!-- 商品销售概况 -->
        <div class="table_h">
            <div class="selectBox">
                <a-select v-model="qudao" @change="handleChange">
                    <a-select-option value="all">渠道（全部）</a-select-option>
                    <a-select-option value="1">收银台</a-select-option>
                </a-select>
            </div>
            <div class="selectBox">
                <a-select v-model="category_id" @change="cateChange">
                    <a-select-option value="all">全部分组</a-select-option>
                    <a-select-option v-for="item in category" :key="item.category_id" :value="item.category_id">{{item.name}}</a-select-option>
                </a-select>
            </div>
            <div class="selectBox"></div>
            <div class="rightT">数量</div>
            <div class="rightT text_r">小计</div>
        </div>
        <div class="table_b">
            <div class="row" v-for="(item,i) in list" :key="i">
                <div style="flex:4;">{{item.goods_name}}</div>
                <div style="flex:3;">{{item.category_name}}</div>
                <div style="flex:2;"></div>
                <div class="Subtotal">{{item.count}}</div>
                <div class="Subtotal text_r">{{item.price}}</div>
            </div>
        </div>
        <div class="small_total bgColor">
            <div style="flex:4;">渠道小计</div>
            <div style="flex:3;"></div>
            <div style="flex:2;"></div>
            <div class="Subtotal">{{totalData.count}}</div>
            <div class="Subtotal text_r">{{totalData.totalPrice}}</div>
        </div>
        <div class="total_row textColor">
            <span class="color50"> {{totalData.count}} 件，总价:  ￥ {{totalData.totalPrice}} </span>
            <span v-if="FOOD_TYPE == 'feast'">赠菜数量: {{totalData.givingNum}} ，赠菜金额: ￥ {{totalData.givingPrice}}   退菜数量: {{totalData.refundNum}} ，退菜金额: ￥ {{totalData.refundPrice}} </span>
        </div>
    </div>
</template>
<script>
import { goodsSalesOverview , categoryList } from '@/request/shift'
export default {
    name:'sales',
    props:['refresh','timeData','schedule_type'],
    watch:{
        refresh(newVal){
            this.getData()
        }
    },
    data(){
        return{
            category:[],
            qudao:'all',
            category_id:'all',
            totalData:{},
            list:[],
            FOOD_TYPE:''
        }
    },
    mounted(){
        this.FOOD_TYPE = localStorage.getItem('FOOD_TYPE') || 'feast'
        categoryList().then(res => {
            if(res.data.status == 200){
                this.category = res.data.data.list
            }
        })
        this.getData()
    },
    methods:{
        getData(){
            let data = {}
            if(this.timeData && this.timeData.length == 2){
                data = {
                    start_time: this.timeData[0],
                    end_time: this.timeData[1],
                    schedule_type: this.schedule_type
                }
            }else{
                data = {
                    schedule_type: this.schedule_type
                }
            }
            if(this.category_id != 'all'){
                data.category_id = this.category_id
            }
            goodsSalesOverview(data).then(res => {
                if(res.data.status == 200){
                    this.totalData = {
                        count:res.data.data.count,
                        givingNum:res.data.data.givingNum,
                        givingPrice:res.data.data.givingPrice,
                        refundNum:res.data.data.refundNum,
                        refundPrice:res.data.data.refundPrice,
                        totalPrice:res.data.data.totalPrice,
                    }
                    this.list = res.data.data.list
                }
            })
            
        },
        cateChange(){
            this.getData()
        },
        handleChange(){
            
        }
    }
}
</script>
<style lang="less" scoped>
.sales{
    border: 1px solid #d0d0d0;
    .table_h{
        padding: 10px;
        display: flex;
        align-items: center;
        .selectBox{
            flex:1;
            padding-right: 20px;
        }
        .rightT{
            width: 80px;
            margin-right: 6px;
            font-size: 1.8vh;
        }
        
    }
    .table_b{
        height: 42vh;
        overflow-y: auto;
        font-size: 1.8vh;
        .row{
            margin: 0 10px;
            padding: 13px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #E0E0E0;
            .Subtotal{
                width: 80px;
            }
        }
        &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 6px;
            background   : #AAAAAA;
        }
        &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            border-radius: 6px;
            background   : #CCCACA;
        }
    }
    .small_total{
        font-size: 1.8vh;
        padding: 13px 10px;
        display: flex;
        align-items: center;
        .Subtotal{
            width: 80px;
        }
    }
    .total_row{
        padding: 10px;
        font-size: 1.8vh;
        .color50{
            color: #505050;
            padding-right: 10px;
        }
    }
    .text_r{
        text-align: right;
    }
}
::v-deep .ant-select{
    height: 36px;
    width: 100%!important;
}
::v-deep .ant-select-selection--single{
    height: 36px;
    width: 100%;
}
::v-deep .ant-select-selection__rendered{
    line-height: 36px;
    font-size: 1.8vh;
}

</style>