<template>
    <div class="shift">
        <!-- 我的班次弹框 -->
        <a-modal v-model="visible" :footer="null" :maskClosable="false" :width="'90%'" centered>
            <template slot="title">
                <div class="titleName">
                    <div>我的班次</div>
                    <div>操作员：{{username}}</div>
                </div>
            </template>
            <div class="titleBox">
                <div class="searchForm">
                    <span>时间：</span>
                    <a-range-picker 
                     :placeholder="['开始时间' , '结束时间']" v-model="timeData"
                     valueFormat="YYYY-MM-DD" @change="timeChange"
                    >
                        <a-icon slot="suffixIcon" type="calendar" />
                    </a-range-picker>
                    <a-button v-for="item in timeList" :key="item.id"  :class="schedule_type == item.id ? 'searchBtn' : 'cancleBtn'" @click="timeTypeChange(item)">
                        {{item.name}}
                    </a-button>
                </div>
                <div class="right">
                    <div class="btn" @click="PrintHandle">预打印交班单</div>
                </div>
            </div>
            <div class="tabs borderColor">
                <div v-for="item in typeList" :key="item.id" :class="type == item.id ? 'bgColor' : 'border_right_Color'"  @click="type = item.id">
                    {{item.name}}
                </div>
            </div>
            <div class="tabsContent">
                <Business :refresh="refresh" :schedule_type="schedule_type" :timeData="timeData" v-show="type == 1"/>
                <Sales :refresh="refresh" :schedule_type="schedule_type" :timeData="timeData" v-show="type == 2"/>
                <Monad  :refresh="refresh" :schedule_type="schedule_type" :timeData="timeData" v-show="type == 3"/>
                <!-- <Period :refresh="refresh" :schedule_type="schedule_type" :timeData="timeData" v-show="type == 4"/> -->
            </div>

        </a-modal>
    </div>
</template>
<script>
import Business from './business'
import Sales from './sales'
import Monad from './monad'
// import Period from './period'

export default {
    name:'shift',
    props:['showShift'],
    data(){
        return{
            visible:false,
            timeData:[],
            schedule_type: 1,
            timeList:[
                {
                    id: 1,
                    name:'本班次'
                },
                {
                    id: 2,
                    name:'今天'
                },
                {
                    id: 3,
                    name:'一周'
                },
                {
                    id: 4,
                    name:'一个月'
                },
            ],
            type:1,
            typeList:[
                {
                    id: 1,
                    name:'营业概况'
                },
                {
                    id: 2,
                    name:'商品销售概况'
                },
                {
                    id: 3,
                    name:'商品出单概况'
                },
                // {
                //     id: 4,
                //     name:'时段统计'
                // },
            ],
            refresh:1,
            username:''
        }
    },
    mounted(){
        this.visible = this.showShift
        this.username = localStorage.getItem('username')
    },
    watch:{
        visible(newval,val){
            if(!newval){
                setTimeout(() => {
                    this.$emit('hidn')
                },200)
            }
        }
    },
    methods:{
        timeChange(){
            this.schedule_type = null
            this.refresh ++
        },
        timeTypeChange(item){
            this.schedule_type = item.id
            this.timeData = []
            this.refresh ++
        },
        PrintHandle(){
            let value = {}
            if(this.timeData && this.timeData.length == 2){
                value = {
                    start_time: this.timeData[0],
                    end_time: this.timeData[1],
                    schedule_type: this.schedule_type
                }
            }else{
                value = {
                    schedule_type: this.schedule_type
                }
            }
            this.socketApi.websocketsend({key:"jiaobanAction",value});
        }
    },
    components:{
        Business,
        Sales,
        Monad,
        // Period,
    }
}
</script>
<style lang="less" scoped>
.shift{
    background: #edeef2;
}
.titleName{
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    font-weight: 700;
    color: #555;
    height: 3.2vh;
    line-height: 1.5;
    >div:last-child{
        margin-right:1.5vw;
    }
}
.titleBox{
    display: flex;
    justify-content: space-between;
    .right{
        .btn{
            background: #2a82e4;
            color: #fff;
            width: 20vw;
            height: 3.8vh;
            border-radius: 4px;
            text-align: center;
            line-height: 3.8vh;
            cursor: pointer;
            font-size: 1.8vh;
        }
    }
} 
::v-deep .ant-input{
    height: 36px;
}
::v-deep .ant-modal-content{
    background: #edeef2;
}
::v-deep .ant-modal-header{
    background: #edeef2;
    border:none;
}
::v-deep .ant-modal-body{
    padding-top: 0;
}
.tabs{
    display: flex;
    border-radius: 4px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    margin-top: 20px;
    div{
        flex: 1;
        cursor: pointer;
        font-size: 1.8vh;
    }
    div:last-child{
        border:none;
    }
}
.tabsContent{
    height:62vh;
    margin-top: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 5px;
    }
    &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 6px;
        background   : #AAAAAA;
    }
    &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 6px;
        background   : #CCCACA;
    }
}
</style>