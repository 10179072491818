<template>
    <div class="monad">
        <!-- 商品出单概况 -->
        <div class="statistics">
            <div>原单:{{monadData.totalPrice}}</div>
            <div>单数:{{monadData.totalNum}}</div>
            <div>实收:{{monadData.totalPayedPrice}}</div>
            <div>单均:{{monadData.avgPrice}}</div>
            <div>优惠:{{monadData.discountPrice}}</div>
        </div>
        <div class="table_row">
            <div class="item_num">
                <div class="item_t">原单价格</div>
                <div class="item_b">{{monadData.totalPrice}}</div>
            </div>
            <div class="item_num">
                <div class="item_t">净销售单数</div>
                <div class="item_b">{{monadData.totalNum}}</div>
            </div>
            <div class="item_num">
                <div class="item_t">净销售金额</div>
                <div class="item_b">{{monadData.totalPayedPrice}}</div>
            </div>
            <div class="item_num">
                <div class="item_t">单均价</div>
                <div class="item_b">{{monadData.avgPrice}}</div>
            </div>
            <div class="item_num">
                <div class="item_t">优惠金额</div>
                <div class="item_b">{{monadData.discountPrice}}</div>
            </div>
        </div>
        <div class="card_row">
            <div>
                <div class="card_item">
                    <div>前台点单</div>
                    <div>{{ monadData.totalPrice ? monadData.totalPrice.slice(1) : '-'}}</div>
                </div>
                <div class="card_item">
                    <div>服务员点单</div>
                    <div>0</div>
                </div>
                <div class="card_item">
                    <div>扫码点单</div>
                    <div>0</div>
                </div>
            </div>
            <div>
                <div class="card_item">
                    <div>前台点单</div>
                    <div>{{monadData.totalNum}}</div>
                </div>
                <div class="card_item">
                    <div>服务员点单</div>
                    <div>0</div>
                </div>
                <div class="card_item">
                    <div>扫码点单</div>
                    <div>0</div>
                </div>
            </div>
            <div>
                <div class="card_item">
                    <div>前台点单</div>
                    <div>{{monadData.totalPayedPrice ? monadData.totalPayedPrice.slice(1) : '-'}}</div>
                </div>
                <div class="card_item">
                    <div>服务员点单</div>
                    <div>0</div>
                </div>
                <div class="card_item">
                    <div>扫码点单</div>
                    <div>0</div>
                </div>
            </div>
            <div>
                <div class="card_item">
                    <div>前台点单</div>
                    <div>{{monadData.avgPrice ? monadData.avgPrice.slice(1) : '-'}}</div>
                </div>
                <div class="card_item">
                    <div>服务员点单</div>
                    <div>0</div>
                </div>
                <div class="card_item">
                    <div>扫码点单</div>
                    <div>0</div>
                </div>
            </div>
            <div>
                <div class="card_item">
                    <div>前台点单</div>
                    <div>{{monadData.discountPrice ? monadData.discountPrice.slice(1) : '-'}}</div>
                </div>
                <div class="card_item">
                    <div>服务员点单</div>
                    <div>0</div>
                </div>
                <div class="card_item">
                    <div>扫码点单</div>
                    <div>0</div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import { singleProfile } from '@/request/shift'
export default {
    name:'monad',
    props:['refresh','timeData','schedule_type'],
    data(){
        return{
            monadData:{}
        }
    },
    watch:{
        refresh(newVal){
            this.getData()
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            let data = {}
            if(this.timeData && this.timeData.length == 2){
                data = {
                    start_time: this.timeData[0],
                    end_time: this.timeData[1],
                    schedule_type: this.schedule_type
                }
            }else{
                data = {
                    schedule_type: this.schedule_type
                }
            }
            singleProfile(data).then(res => {
                if(res.data.status == 200){
                    this.monadData = res.data.data
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.monad{
    .statistics{
        display: flex;
        padding: 15px 0;
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        font-size: 1.8vh;
        div{
            margin-right: 30px;
        }
    }
    .table_row{
        display: flex;
        text-align: center;
        border: 1px solid #d0d0d0;
        border-right:none;
        margin-top: 20px;
        background: #ffffff;
        .item_num{
            flex: 1;
            border-right: 1px solid #d0d0d0;
            padding: 10px;
            font-size: 1.8vh;
            .item_b{
                font-weight: 700;
                margin-top: 2px;
                font-size: 1.8vh;
            }
        }
    }
    .card_row{
        display: flex;
        margin-top: 6px;
        height: 300px;
        >div{
            margin-right: 3px;
            flex: 1;
            padding: 15px;
            background: #ffffff;
            border: 1px solid #d0d0d0;
        }
        .card_item{
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            font-size: 1.8vh;
        }
        >div:last-child{
            margin: 0;
        }
    }
}
</style>