//我的班次
import request from "@/request/request";


// 营业概况
export function businessSituation(data) {
  return request({
    url: "/clerk/order/businessSituation",
    method: "post",
    data
  });
}

//商品分类 下拉框
export function categoryList(data) {
    return request({
      url: "/clerk/category/list",
      method: "post",
      data: data,
    });
  }
// 商品销售概况
export function goodsSalesOverview(data) {
    return request({
      url: "/clerk/order/goodsSalesOverview",
      method: "post",
      data
    });
}

// 商品出单概况
export function singleProfile(data) {
    return request({
      url: "/clerk/order/singleProfile",
      method: "post",
      data
    });
}

// 时段统计
// export function timeStatistics(data) {
//     return request({
//       url: "/clerk/order/timeStatistics",
//       method: "post",
//       data
//     });
// }

// 交班退出
export function schedule(params) {
    return request({
      url: "/clerk/shop/schedule",
      method: "get",
      params
    });
}